import React, { PropsWithChildren, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import PackageOption from './PackageOption';
import { twMerge } from 'src/lib/mergeTailwind';
import { IconSize } from '../Icon/Icon';
type PackageSelectorProps = {
  name: string;
  control: Control;
  defaultValue?: string | number;
  rules?: {
    required?: string | boolean;
    validate?: (value: string | number | object) => string | boolean;
  };
  ariaControls?: string;
  disabled?: boolean;
  error?: string;
  containerClassName?: string;
  iconSize?: IconSize;
  optionsClassname?: string;
  afterChange?: (value: string | number) => void;
};

const PackageSelector = ({
  name,
  control,
  defaultValue,
  rules,
  ariaControls,
  children,
  disabled = false,
  error,
  containerClassName,
  iconSize = '4xl',
  optionsClassname,
  afterChange = () => {},
}: PropsWithChildren<PackageSelectorProps>) => {
  const options = useMemo(
    () =>
      children && React.Children.count(children) > 0
        ? React.Children.map(children, (_child) => {
            if (_child && React.isValidElement(_child)) {
              return _child.props.value;
            }
            return null;
          })?.filter((value) => value !== null && value !== undefined)
        : [],
    [children]
  );

  if (rules?.required) {
    const customError = rules.required;
    const customValidate = rules.validate;

    rules.validate = (v) => {
      const isValidValue =
        options && options.filter((value) => value === v).length > 0;
      if (isValidValue && v !== null && v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };

    rules.required = false;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <>
          <div
            className={twMerge(
              'flex flex-row flex-wrap items-center justify-center space-y-4 md:space-y-0',
              containerClassName
            )}
          >
            {React.Children.map(
              children,
              (_child, i) =>
                React.isValidElement(_child) &&
                React.cloneElement(
                  _child as React.ReactElement,
                  !_child.props.children
                    ? {
                        name,
                        id: name + i,
                        ariaControls,
                        onChange: (e: string | number) => {
                          onChange(e);
                          afterChange(e);
                        },
                        selectedValue: value,
                        error: error,
                        disabled: disabled,
                        inputRef: i === 0 && ref,
                        iconSize: _child.props.iconSize || iconSize,
                        className: twMerge(
                          _child.props.className,
                          optionsClassname
                        ),
                      }
                    : {}
                )
            )}
          </div>
          <div className="text-error text-center text-sm mt-6">{error}</div>
        </>
      )}
    />
  );
};

const Option = PackageOption;

PackageSelector.Option = Option;

export default PackageSelector;

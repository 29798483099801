import dayjs from 'dayjs';
import set from 'lodash.set';
import unset from 'lodash.unset';
import { PRODUCTS } from 'src/products/products';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const TypeOfCoverageObject = { DOLLAR: 'DOLLAR', PERCENTAGE: 'PERCENTAGE' };

const createCoverageObject = (value, type) => {
  return {
    Value: value,
    Type: type,
  };
};

const unroundedFormatter = (numero) => {
  const partes = numero.toString().split('.');
  const parteEntera = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const parteDecimal = partes[1] ? `.${partes[1]}` : '.';
  return `${parteEntera}${parteDecimal}`;
};

export const identityTransform = {
  input: (v) => v,
  output: (v) => v,
};

export const roundUp = (value, step) =>
  value % step === 0 ? value : value - (value % step) + step;
export const roundDown = (value, step) =>
  !!value || value === 0 ? Math.floor(value / step) * step : null;
export const truncate = (value, step) =>
  value % step === 0 ? value : Math.trunc(value);

export const currencyTransform = {
  input: (v) =>
    v || v === 0 ? formatter.format(v).replace(/\D00(?=\D*$)/, '') : undefined,
  output: (v) => v && Number(v.replace(/\$|,/g, '')),
};

export const unroundedTransform = {
  input: (v) => {
    if (typeof v === 'string' && v.includes('.') && !/^\d+\.\d{2}$/.test(v)) {
      return `$${unroundedFormatter(v)}`;
    }
    if (v || v === 0) {
      return formatter.format(v).replace(/\D00(?=\D*$)/, '');
    }
    return '$0';
  },
  output: (v) => v && Number(v.replace(/\$|,/g, '')),
};

export const dateTransform = {
  input: (v) =>
    v && v.length === 10 && dayjs(v).isValid()
      ? dayjs(v).format('MM/DD/YYYY')
      : v,
  output: (v) => {
    if (v && v.length === 10 && dayjs(v).isValid()) {
      return dayjs(v).format('YYYY-MM-DD');
    }
    return v;
  },
};

export const phoneNumberTransform = {
  transform: (v) => {
    let phoneNumber =
      v && v.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
    return `${phoneNumber[1]}-${phoneNumber[2]}-${phoneNumber[3]}`;
  },
};

export const coveragesTransform = {
  input: (inputValue) => {
    if (!inputValue) {
      return;
    }
    const value = JSON.parse(JSON.stringify(inputValue));

    if (value.Coverages) {
      value.Coverages.CoverageA = currencyTransform.input(
        value?.Coverages?.CoverageA
      );
      value.Coverages.CoverageC = currencyTransform.input(
        value?.Coverages?.CoverageC
      );
      value.Coverages.CoverageD = currencyTransform.input(
        value?.Coverages?.CoverageD
      );
    }

    if (value.OptionalCoverages) {
      value.OptionalCoverages.PermittedIncidentalOccupancyResidencePremises.BusinessLimit =
        currencyTransform.input(
          value?.OptionalCoverages
            ?.PermittedIncidentalOccupancyResidencePremises?.BusinessLimit
        );

      if (value.OptionalCoverages.ScheduledPersonalProperty) {
        value?.OptionalCoverages?.ScheduledPersonalProperty.forEach((spp) => {
          spp.SPPLimit = currencyTransform.input(spp.SPPLimit);
          delete spp.SPPFileStream;
        });
      }
    }

    return value;
  },
  output: (coverages, formType) => {
    if (!coverages) {
      return {};
    }

    coverages.CoverageA = createCoverageObject(
      currencyTransform.output(coverages?.CoverageA),
      TypeOfCoverageObject.DOLLAR
    );
    if (coverages.CoverageB) {
      delete coverages.CoverageB;
    }
    coverages.CoverageC =
      formType === PRODUCTS.FLORIDA_HO6.FormType
        ? createCoverageObject(
            currencyTransform.output(coverages.CoverageC),
            TypeOfCoverageObject.DOLLAR
          )
        : createCoverageObject(
            coverages.CoverageC,
            TypeOfCoverageObject.PERCENTAGE
          );
    if (coverages.CoverageD) {
      if (formType === PRODUCTS.FLORIDA_HO3.FormType) {
        delete coverages.CoverageD;
      } else {
        coverages.CoverageD = createCoverageObject(
          currencyTransform.output(coverages.CoverageD),
          TypeOfCoverageObject.DOLLAR
        );
      }
    }

    return coverages;
  },
};

const windMitFormMapping = {
  RoofCover: {
    'A': 'FBC Equivalent',
    'B': 'FBC Equivalent',
    'C': 'Non-FBC Equivalent',
    'D': 'Non-FBC Equivalent',
    'Inspector Did Not Specify': 'Unknown',
    'Level B': 'FBC Equivalent',
    'Level A': 'Non-FBC Equivalent',
  },
  RoofDeckAttachment: {
    'A': `6d @ 6"/12`,
    'B': `8d @ 6"/12`,
    'C': `8d @ 6"/6" Dimensional Lumber Deck`,
    'D': 'Reinforced Concrete Roof Deck',
    'E': 'Unknown',
    'F': 'Unknown',
    'G': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Level A': `6d @ 6"/12`,
    'Level B': `8d @ 6"/12`,
    'Level C': 'Reinforced Concrete Roof Deck',
  },
  RoofToWallConnection: {
    'A': 'Toe Nails',
    'B': 'Clips',
    'C': 'Single Wraps',
    'D': 'Double Wraps',
    'E': 'Reinforced Concrete Roof Deck',
    'F': 'Unknown',
    'G': 'Unknown',
    'H': 'Unknown',
    'Inspector Did Not Specify': 'N/A',
  },
  RoofGeometry: {
    'A': 'Hip',
    'B': 'Flat',
    'C': 'Gable',
    'Inspector Did Not Specify': 'Other',
  },
  SecondaryWaterResistance: {
    'A': 'Yes SWR',
    'B': 'No SWR',
    'C': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Underlayment': 'Yes SWR',
    'Foamed Adhesive': 'No SWR',
  },
  OpeningProtection: {
    'A.1': 'Hurricane - Windows or All',
    'A.2': 'Hurricane - Windows or All',
    'A.3': 'Hurricane - Windows or All',
    'B.1': 'Basic - Windows or All',
    'B.2': 'Basic - Windows or All',
    'B.3': 'Basic - Windows or All',
    'C.1': 'None',
    'C.2': 'None',
    'C.3': 'None',
    'N.1': 'None',
    'N.2': 'None',
    'N.3': 'None',
    'X': 'None',
    'Inspector Did Not Specify': 'Unknown',
    'Class A (Hurricane Impact)': 'Hurricane - Windows or All',
    'Class B (Basic Impact)': 'Basic - Windows or All',
  },
};

export const windMitTransform = {
  input: (v) => {
    if (!v?.RoofCover) {
      return {};
    }
    v.RoofCover = windMitFormMapping.RoofCover[v.RoofCover];
    v.RoofDeckAttachment =
      windMitFormMapping.RoofDeckAttachment[v.RoofDeckAttachment];
    v.RoofToWallConnection =
      windMitFormMapping.RoofToWallConnection[v.RoofToWallConnection];
    v.RoofGeometry = windMitFormMapping.RoofGeometry[v.RoofGeometry];
    v.SecondaryWaterResistance =
      windMitFormMapping.SecondaryWaterResistance[v.SecondaryWaterResistance];
    v.OpeningProtection =
      windMitFormMapping.OpeningProtection[v.OpeningProtection];

    return v;
  },
};

export const requiredMissingInfoTransform = {
  input: (v) => {
    let RequiredMissingInfo = [];

    if (!(parseInt(v.YearBuilt) > 0)) {
      RequiredMissingInfo.push('YearBuilt');
    }
    if (!(parseInt(v.RoofYear) > 0)) {
      RequiredMissingInfo.push('RoofYear');
    }
    if (!(parseInt(v.SquareFeet) > 0)) {
      RequiredMissingInfo.push('SquareFeet');
    }
    if (!v.RoofMaterial) {
      RequiredMissingInfo.push('RoofMaterial');
    }
    if (!v.RoofShape) {
      RequiredMissingInfo.push('RoofShape');
    }
    if (!v.Construction) {
      RequiredMissingInfo.push('Construction');
    }
    if (!v.DwellingType) {
      RequiredMissingInfo.push('DwellingType');
    }
    if (
      !(parseInt(v.YearBuilt) > 0) ||
      parseInt(v.YearBuilt) <= dayjs().add(-16, 'year').year()
    ) {
      RequiredMissingInfo.push('HotWaterHeater');
    }
    return RequiredMissingInfo;
  },
};

export const quoteTransform = {
  input: (quote) => {
    if (!quote.MissingInfo) {
      quote.MissingInfo = {};
    }

    quote.MissingInfo.YearBuilt =
      quote.MissingInfo?.YearBuilt !== '0'
        ? quote.MissingInfo?.YearBuilt
        : null;
    quote.MissingInfo.RoofYear =
      quote.MissingInfo?.RoofYear !== '0' ? quote.MissingInfo?.RoofYear : null;
    quote.MissingInfo.SquareFeet =
      quote.MissingInfo?.SquareFeet !== '0'
        ? quote.MissingInfo?.SquareFeet
        : null;

    quote.RequiredMissingInfo = requiredMissingInfoTransform.input(
      quote.MissingInfo
    );

    if (quote.Coverages) {
      quote.Coverages.CoverageA = quote.Coverages.CoverageA?.Value;
      quote.Coverages.CoverageC = quote.Coverages.CoverageC?.Value;
      quote.Coverages.CoverageD = quote.Coverages.CoverageD?.Value;
      if (quote.Coverages.CoverageB?.Value) {
        quote.Coverages.CoverageB = quote.Coverages.CoverageB?.Value;
      }
    }

    if (quote.Deductibles) {
      quote.Deductibles.HurricaneDeductible = formatHurricaneDeductible(
        quote.Deductibles.HurricaneDeductible
      );
      quote.Deductibles.OtherPerils = formatOtherPerils(
        quote.Deductibles.OtherPerils
      );
    }

    if (quote.Interview?.PreviousCarrier) {
      quote.PreviousCarrier = {
        PreviousCarrier: quote.Interview.PreviousCarrier,
        PriorExpirationDate: quote.Interview.PriorExpirationDate || null,
      };
      unset(quote, 'Interview.PreviousCarrier');
    }

    if (quote.OtherStructures?.OnPremises) {
      set(
        quote,
        'OtherStructures.hasOnPremises',
        quote.OtherStructures?.OnPremises &&
          quote.OtherStructures?.OnPremises?.length > 0
      );
    }

    if (quote.OtherStructures?.hasOnPremises) {
      quote.OtherStructures?.OnPremises.forEach((item, index) => {
        set(
          quote.OtherStructures?.OnPremises,
          `[${index}].Limit`,
          Number(item.Limit) === 0
            ? 0
            : Math.ceil((Number(item.Limit) / quote.Coverages.CoverageA) * 100)
        );
        if (item?.Description?.includes('Other -')) {
          set(
            quote.OtherStructures?.OnPremises,
            `[${index}].OtherDescription`,
            item.Description.replace('Other - ', '')
          );
          set(
            quote.OtherStructures?.OnPremises,
            `[${index}].Description`,
            'Other'
          );
        }
      });
    } else {
      unset(quote, 'OtherStructures.OnPremises');
    }

    if (quote.OtherStructures?.RentedToOthers) {
      set(
        quote,
        'OtherStructures.hasRentedToOthers',
        quote.OtherStructures?.RentedToOthers &&
          quote.OtherStructures?.RentedToOthers?.length > 0
      );
    }

    if (quote.OtherStructures?.hasRentedToOthers) {
      quote.OtherStructures?.RentedToOthers.forEach((item, index) => {
        set(
          quote.OtherStructures?.RentedToOthers,
          `[${index}].Limit`,
          Number(item.Limit) === 0
            ? 0
            : Math.ceil((Number(item.Limit) / quote.Coverages.CoverageA) * 100)
        );
        if (item?.Description?.includes('Other -')) {
          set(
            quote.OtherStructures?.RentedToOthers,
            `[${index}].OtherDescription`,
            item.Description.replace('Other - ', '')
          );
          set(
            quote.OtherStructures?.RentedToOthers,
            `[${index}].Description`,
            'Other'
          );
        }
      });
    } else {
      unset(quote, 'OtherStructures.RentedToOthers');
    }

    if (quote.OptionalCoverages) {
      const optionalCoveragesFields = Object.keys(quote.OptionalCoverages);

      optionalCoveragesFields.forEach((optionalCoverage) => {
        if (quote?.OptionalCoverages[optionalCoverage]?.Premium) {
          if (quote.OptionalCoverages.Premiums) {
            quote.OptionalCoverages.Premiums[optionalCoverage] =
              quote.OptionalCoverages[optionalCoverage].Premium;
          } else {
            quote.OptionalCoverages.Premiums = {};
            quote.OptionalCoverages.Premiums[optionalCoverage] =
              quote.OptionalCoverages[optionalCoverage].Premium;
          }
        }
        if ('Value' in quote.OptionalCoverages[optionalCoverage]) {
          quote.OptionalCoverages[optionalCoverage] =
            quote.OptionalCoverages[optionalCoverage].Value;
        }
      });

      if (quote?.OptionalCoverages?.ScheduledPersonalProperty?.length > 0) {
        for (
          let i = 0;
          i < quote.OptionalCoverages.ScheduledPersonalProperty.length;
          i++
        ) {
          if (
            quote.OptionalCoverages.ScheduledPersonalProperty[i]?.SPPFileName
              ?.length > 0
          ) {
            quote.OptionalCoverages.ScheduledPersonalProperty[i].SPPFileStream =
              new File(
                [],
                `${quote.OptionalCoverages.ScheduledPersonalProperty[i].SPPFileName}.pdf`,
                { type: 'application/pdf' }
              );
            delete quote.OptionalCoverages.ScheduledPersonalProperty[i]
              .SPPFileName;
          }
        }
      }
    }
    if (quote.DwellingInfo) {
      if (quote.DwellingInfo.AttachedScreenEnclosure) {
        quote.DwellingInfo.AttachedScreenEnclosure.SizeSqFt = Number(
          quote.DwellingInfo.AttachedScreenEnclosure.SizeSqFt
        );
        if (!quote.DwellingInfo.AttachedScreenEnclosure.SizeSqFt > 0) {
          delete quote.DwellingInfo.AttachedScreenEnclosure.SizeSqFt;
        }
      }
      if (quote.DwellingInfo.DetachedScreenEnclosure) {
        quote.DwellingInfo.DetachedScreenEnclosure.SizeSqFt = Number(
          quote.DwellingInfo.DetachedScreenEnclosure.SizeSqFt
        );
        if (!quote.DwellingInfo.DetachedScreenEnclosure.SizeSqFt > 0) {
          delete quote.DwellingInfo.DetachedScreenEnclosure.SizeSqFt;
        }
      }
    }

    const pathsToConvertToNumber = [
      'Underwriting.FirstPartyLimit',
      'Underwriting.ThirdPartyLimit',
    ];
    pathsToConvertToNumber.forEach((path) =>
      convertPropertyToNumber(quote, path)
    );

    return quote;
  },
};

export function convertPropertyToNumber(obj, path) {
  const keys = path.split('.');
  const lastKey = keys.pop();
  let target = obj;

  // Traverse to the target object
  for (const key of keys) {
    if (target[key] === undefined) {
      return;
    } // Exit if path is invalid
    target = target[key];
  }

  // Convert value to number if it exists
  if (target[lastKey] !== undefined) {
    target[lastKey] = Number(target[lastKey]);
  }
}

export const formatHurricaneDeductible = (value) => {
  switch (value) {
    case '0':
      return 'Excluded';
    case '500':
      return '$500';
    case '1000':
      return '$1,000';
    case '1':
      return '1%';
    case '2':
      return '2%';
    case '5':
      return '5%';
    case '10':
      return '10%';
    default:
      return 'Invalid amount';
  }
};

export const formatOtherPerils = (value) => {
  switch (value) {
    case '500':
      return '$500';
    case '1000':
      return '$1,000';
    case '2500':
      return '$2,500';
    case '5000':
      return '$5,000';
    case '7500':
      return '$7,500';
    case '10000':
      return '$10,000';
    default:
      return 'Invalid amount';
  }
};
